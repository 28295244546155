<template>
  <div class="tc-group">
	<div class="tool printbtn">
	  <el-button type="success" @click="print">打印或导出PDF文件</el-button>
	  <!-- <el-button type="success" @click="exportWord">导出Word文件</el-button><br /><br /> -->
	  <el-button type="danger" @click="close">关闭</el-button><br /><br />
	  <!-- 筛选日期 -->
	  <div class="setDate" v-if="true">
	    <!-- <hr /><br />
	    日期范围 :<el-date-picker v-model="staDate" type="date" placeholder="开始日期" value-format="timestamp"></el-date-picker>
	    至<el-date-picker v-model="endDate" type="date" placeholder="介绍日期" value-format="timestamp"></el-date-picker>
	    <el-button icon="el-icon-search" circle type="success" @click="loadData"></el-button><br />
	    <br /><hr /> -->
	    <p>使用说明：</p>
	    <span class="msg">1、导出PDF文件，请在 “打印机中” 选择 PDF</span>
	    <div class="rightClose" @click="closeTool">
	      <i :class="closeToolCut==1? 'el-icon-d-arrow-left':'el-icon-d-arrow-right'"></i>
	    </div>
	  </div>
	</div>
	<!-- ****** 打印、导出pdf****** -->
	<div class="tc-group-layout">
	  <div class="normal">
		<h1><span>{{unitname}}</span><img :src="logourl" /></h1>
		<h2 style="font-size: 35px;">{{title}}</h2>
		<h2 style="padding-bottom: 20px;">团体心理健康筛查测评报告</h2>
		<table cellspacing="0" class="item">
		  <tr><th colspan="2">筛选项目</th></tr>
		  <tr v-if="scxm.length === 0"><td colspan="2">暂无数据</td></tr>
		  <tr v-for="item,index in scxm" :key="index"><td>{{item.measure_title}}</td><td>{{item.evaluating_time}}</td></tr>
		</table>
		<table cellspacing="0" class="object">
		  <tr><th colspan="6">筛选对象</th></tr>
		  <tr><td>应检总数</td><td>{{scdx.need.sum}}</td><td>男生应检</td><td>{{scdx.need.boy}}</td><td>女生应检</td><td>{{scdx.need.girl}}</td></tr>
		  <tr><td>实际参检</td><td>{{scdx.per.all}}</td><td>实际参检</td><td>{{scdx.per.boy}}</td><td>实际参检</td><td>{{scdx.per.girl}}</td></tr>
		  <tr><td>实际到检</td><td>{{scdx.real.sum}}</td><td>实际到检</td><td>{{scdx.real.boy}}</td><td>实际到检</td><td>{{scdx.real.girl}}</td></tr>
		</table>
		<table cellspacing="0" class="age-sex">
		  <tr><th colspan="8">年龄及性别分布</th></tr>
		  <tr><td rowspan="2"></td><td colspan="2">&lt;12岁</td><td colspan="2">12~15岁</td><td colspan="2">&gt;15岁</td><td rowspan="2">合计</td></tr>
		  <tr><td>人数</td><td>占比</td><td>人数</td><td>占比</td><td>人数</td><td>占比</td></tr>
		  <tr>
		    <td>男性</td><td>{{nnjxbfb.one_boy}}</td>
		    <td>{{mantol == 0? 0:((nnjxbfb.one_boy/mantol)*100).toFixed(2)}}%</td><td>{{nnjxbfb.two_boy}}</td>
		    <td>{{mantol == 0? 0:((nnjxbfb.two_boy/mantol)*100).toFixed(2)}}%</td><td>{{nnjxbfb.three_boy}}</td>
		    <td>{{mantol  == 0? 0:((nnjxbfb.three_boy/mantol)*100).toFixed(2)}}%</td><td>{{mantol}}</td>
		  </tr>
		  <tr>
		    <td>女性</td><td>{{nnjxbfb.one_girl}}</td>
		    <td>{{womantol === 0? 0:((nnjxbfb.one_girl/womantol)*100).toFixed(2)}}%</td><td>{{nnjxbfb.two_girl}}</td>
		    <td>{{womantol === 0? 0:((nnjxbfb.two_girl/womantol)*100).toFixed(2)}}%</td><td>{{nnjxbfb.three_girl}}</td>
		    <td>{{womantol === 0? 0:((nnjxbfb.three_girl/womantol)*100).toFixed(2)}}%</td><td>{{womantol}}</td>
		  </tr>
		  <tr>
		    <td>合计</td><td>{{nnjxbfb.one}}</td>
		    <td>{{nnjxbfb.sum === 0?0:((nnjxbfb.one/nnjxbfb.sum)*100).toFixed(2)}}%</td><td>{{nnjxbfb.two}}</td>
		    <td>{{nnjxbfb.sum === 0?0:((nnjxbfb.two/nnjxbfb.sum)*100).toFixed(2)}}%</td><td>{{nnjxbfb.three}}</td>
		    <td>{{nnjxbfb.sum === 0?0:((nnjxbfb.three/nnjxbfb.sum)*100).toFixed(2)}}%</td><td>{{nnjxbfb.sum}}</td>
		  </tr>
		  <!-- <tr class="static"><td colspan="8"><span>男性</span><span>女性</span><span>合计</span></td></tr> -->
		  <tr><td colspan="8"><div class="agesexchart"><div id="chart-report-1" :style="{ width: '98%', height: '98%' }"></div></div></td></tr>
		</table>
		<div class="PageNext" v-if="mzfb.length > 2"></div>
		<table cellspacing="0" class="nation">
		  <tr><th colspan="6">民族分布</th></tr>
		  <tr v-for="item,index in mzfb" :key="index">
		    <td v-for="itm,idx in item" :key="idx">
		      <div>
		        <div class="name">{{itm.name}}</div>
		        <div>{{itm.num}}</div>
		      </div>
		    </td>
		  </tr>
		</table>
		<!-- 子项目表 -->
		<div class="analysis" v-for="item,index in single" :key="index">
		  <div class="PageNext"></div>
		  <h2 style="padding-bottom: 20px;">{{item.measure_title}}</h2>
		  <table cellspacing="0" class="age-sex total-box" v-if="JSON.stringify(item.ztqk) !== '[]'">
		    <tr><th colspan="7">总体情况</th></tr>
		    <tr><td rowspan="2"></td><td colspan="2">总数</td><td colspan="2">男</td><td colspan="2">女</td></tr>
		    <tr><td>人数</td><td>占比</td><td>人数</td><td>占比</td><td>人数</td><td>占比</td></tr>
		    <tr v-for="itm,idx in item.ztqk" :key="idx">
		      <td>{{itm.factor_name}}</td>
		      <td>{{itm.sum}}</td><td>{{itm.sum_per}}%</td>
		      <td>{{itm.boy}}</td><td>{{itm.boy_per}}%</td>
		      <td>{{itm.girl}}</td><td>{{itm.girl_per}}%</td>
		    </tr>
		  </table>
		  <table cellspacing="0" class="age-sex total-box nianji" v-if="JSON.stringify(item.njqk) !== '[]'">
		    <tr><th :colspan="item.njrowlaber.length * 2">年级情况</th></tr>
		  	<tr>
		  	  <td rowspan="2"></td>
		  	  <td v-for="n1,nx in item.njrowlaber" :key="nx" colspan="2">{{n1}}</td>
		  	</tr>
		  	<tr>
		  	  <td colspan="2" v-for="n2,nx2 in item.njrowlaber" :key="nx2" class="ite-box">
		  	    <div><span>人数</span><span>占比</span></div>
		  	  </td>
		  	</tr>
		  	<tr v-for="n3 in item.njlaber" :key="n3.id">
		  	  <td>{{n3.name === undefined?'总计':n3.name}}</td>
		  	  <td v-for="itm7,idx7 in n3.cont" :key="idx7" colspan="2" class="ite-box">
		  	    <div><span>{{itm7[0]}}</span><span>{{itm7[1]}}</span></div>
		  	  </td>
		  	</tr>
		  </table>
		  <table cellspacing="0" class="age-sex total-box nianji" v-if="JSON.stringify(item.bjqk) !== '[]'">
		    <tr><th :colspan="item.bjrowlaber.length * 2">班级情况</th></tr>
			<tr>
			  <td rowspan="2"></td>
			  <td v-for="v1,idx in item.bjrowlaber" :key="idx" colspan="2">{{v1}}</td>
			</tr>
			<tr>
			  <td colspan="2" v-for="v2,idx2 in item.bjrowlaber" :key="idx2" class="ite-box">
			    <div><span>人数</span><span>占比</span></div>
			  </td>
			</tr>
			<tr v-for="v3 in item.bjlaber" :key="v3.id">
			  <td>{{v3.name}}</td>
			  <td v-for="itm5,idx5 in v3.cont" :key="idx5" colspan="2" class="ite-box">
			    <div><span>{{itm5[0]}}</span><span>{{itm5[1]}}</span></div>
			  </td>
			</tr>
		  </table>
		  <div class="PageNext" v-if="JSON.stringify(item.itemobj) !== '[]'"></div>
		  <!-- :class="[{ PageNext: llfid==0 && item.itemobj.length > 1}, 'class-item']" -->
		  <!-- <div style="height: 900px;transform:rotate(90deg);margin: 0 20px;"> -->
			  <table cellspacing="0" class="class-item PageNext" v-for="llf,llfid in item.itemobj" :key="llfid">
			  			<tr><th>{{gradematching[llf.name]}}{{item.measure_title}}情况图表分析</th></tr>
			  			<tr>
			  			  <td colspan="8" class="charttdbox">
			  				<div class="chart">
			  				  <ItemWorld :msg="llf" ref="childEl" :idx="llf.id" />
			  				</div>
			  			  </td>
			  			</tr>
			  </table>
		  <!-- </div> -->
		  <!-- <div class="PageNext" v-if="item.zjfx.length > 310 && item.itemobj.length > 1"></div> -->
		  <table cellspacing="0">
		    <tr><td>总结与分析</td></tr>
		    <tr v-if="item.zjfx !== ''"><td style="text-align: left;text-indent: 2em;padding: 10px;"><div v-html="item.zjfx"></div></td></tr>
			<tr v-if="item.zjfx === ''"><td style="text-align: center;">无</td></tr>
		  </table>
		</div>
		<div>
			<table cellspacing="0" class="object">
			  <tr>
			    <td>报告时间</td><td>{{getTime()}}</td><td>签名</td><td></td>
			  </tr>
			</table>
		</div>
	  </div>
	</div>
  </div>
</template>
<script>
import { schoolStatistics } from "@/api/report.js";
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';
import ItemWorld from './item.vue';
import {
  selectConfig,
} from "@/api/comm.js";

export default {
  components: {
    ItemWorld
  },
  data() {
    return {
      imgUrl: [], // 图表容器
      sexData: {},
      closeToolCut:1,
      staDate: "",
      endDate: "",
	  scxm: [], // 筛选项目
	  scdx: {
	    need:{},
	    per: {},
	    real: {}
	  }, // 筛选对象
	  mzfb: {}, // 民族分布
	  nnjxbfb: {}, // 年龄和性别分布
	  mantol: 0, // 年龄男生总数
	  womantol: 0, // 年龄女生总数
	  agesexfb: { // 年龄和性别分布圖表
	    name: ['<12岁', '12~15岁', '>15岁'],
	    nan: [],
	    nv: [],
	    tol: []
	  },
	  single: [],
	  gradematching:{
		'1年级': '一年级','2年级': '二年级','3年级': '三年级','4年级': '四年级','5年级': '五年级','6年级': '六年级',
	  },
	  title: '',
	  unitname: '',
	  logourl: '',
    };
  },
  created() {
    this.loadData();
	selectConfig({ config_type: "system_config,user_config_scope" }).then((res) => {
		if (!res) return;
		let { unit_name, logo_url } = res.data;
		this.unitname = unit_name;
		this.logourl = logo_url;
	})
  },
  // mounted() {
  //   this.loadData();
  // },
  methods: {
	// 获取当前时间
	 getTime() {
	    let aData = new Date();
	    let dateValue = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
	    return dateValue;
	},
    // 打印
    print() {
      window.print();
    },
    // 导出word
    // exportWord() {
    //   $("#downWord").wordExport(`铜仁市第一中学-团体心理健康筛查测评报告`);
    // },
    // 关闭
    close() {
      window.close();
    },
    // 收缩工具
    closeTool() {
      if(this.closeToolCut == 1){
       document.querySelector(".tool").style.left = -400 + "px";
       this.closeToolCut = 0
      }else{
        document.querySelector(".tool").style.left = 20 + "px";
       this.closeToolCut = 1
      }
    },
	// 分割民族数组
	fenge(arr, n){
	  const result = [];
	  for(let i =0; i < arr.length;i+=n){
	    result.push(arr.slice(i, i+n))
	  }
	  return result;
	},
	// 处理民族数据
	arrangementNation(row){
	  let arr = [];
	  for(const i in row){
	    const temp = {};
	    temp.name = i;
	    temp.num = row[i];
	    arr.push(temp);
	  }
	  arr = arr.filter((item) => item.name !== 'all');
	  arr.push({ name: '总计', num: row.all });
	  this.mzfb = this.fenge(arr, 6);
	},
	// 处理年龄分布图表
	arrangementAgeSexChart(row){
	  this.mantol = Number(row.one_boy) + Number(row.two_boy) + Number(row.three_boy);
	  this.womantol = Number(row.one_girl) + Number(row.two_girl) + Number(row.three_girl);
	  this.agesexfb = {
	    name: ['<12岁', '12~15岁', '>15岁'],
	    nan: [],
	    nv: [],
	    tol: []
	  }
	  this.agesexfb.nan.push(row.one_boy, row.two_boy, row.three_boy);
	  this.agesexfb.nv.push(row.one_girl, row.two_girl, row.three_girl);
	  this.agesexfb.tol.push(row.one, row.two, row.three);
	  const maxarr = [];
	  maxarr.push(...this.agesexfb.nan, ...this.agesexfb.nv, ...this.agesexfb.tol);
	  this.$nextTick(() => {
	    this.chart(maxarr);
	  });
	},
	// 处理因子图表
	arrangementAItemChart(row){
	 this.single.forEach((item, index) =>{
	   let obj = [];
	   for( const i in item.tbys){
		// console.log(item.tbys[i], i, '第一次');
		let arr = { id: uuidv4(), name: i, cont: {name: [], laber: [], data: []} };
		for (const j in item.tbys[i]){
			arr.cont.name.push(j);
			// console.log(j, item.tbys[i][j], '第二次');
			for(const z in item.tbys[i][j]){
				// console.log(z, item.tbys[i][j][z], '第三次');
				arr.cont.laber.push(z);
				arr.cont.data.push(item.tbys[i][j][z]);
			}
		}
		arr.cont.laber = Array.from(new Set(arr.cont.laber));
		const newarr = [];
		const newdata = this.fenge(arr.cont.data, arr.cont.laber.length);
		// console.log(newdata, '第四次');
		for (let i = 0; i < arr.cont.laber.length; i++){
			newdata.forEach((itm,idx) => {
				itm.forEach((itm2, idx2) => {
					if (i === idx2){
						newarr.push(itm2);
					}
				})
			})
		}
		// arr.cont.laber.forEach((itm, idx) => {
		//   newdata.push(arr.cont.data[idx], arr.cont.data[idx + arr.cont.laber.length]);
		// });
		arr.cont.data = newarr;
		obj.push(arr);
	   }
	   item['itemobj'] = obj;
	   // console.log(obj , "测试数据");
	 });
	  // return 1;
	},
	// 处理班级情况
	onClass(){
	  this.single.forEach((item, index) =>{
	    let obj = [];
		let laber = {};
	    for (const i in item.bjqk){
		  for(const j in item.bjqk[i]){
			obj.push(j);
			if (!laber[item.bjqk[i][j].class_name]){
			  laber[item.bjqk[i][j].class_name] = { name: item.bjqk[i][j].class_name, cont: [], id: uuidv4() };
			}
			const str = [];
			str.push(item.bjqk[i][j].num, item.bjqk[i][j].per);
			laber[item.bjqk[i][j].class_name].cont.push(str);
		  }
	    }
		item['bjlaber'] = Object.values(laber);
		item['bjrowlaber'] = Array.from(new Set(obj));
	  });
	  // console.log(this.single);
	},
	// 处理年级情况
	onGrades(){
	  this.single.forEach((item, index) =>{
	    let obj = [];
	    let laber = {};
	    for (const i in item.njqk){
	      for (const j in item.njqk[i]){
			obj.push(j);
			if (!laber[item.njqk[i][j].grade_name]){
		      laber[item.njqk[i][j].grade_name] = { name: item.njqk[i][j].grade_name, cont: [], id: uuidv4() };
			}
			const str = [];
			str.push(item.njqk[i][j].num, item.njqk[i][j].per);
			laber[item.njqk[i][j].grade_name].cont.push(str);
		  }
	    }
	    item['njlaber'] = Object.values(laber);
	    item['njrowlaber'] = Array.from(new Set(obj));
		// console.log(this.single, "年级情况");
	  });
	},
	// 获取数据
	loadData(){
	  let queryData = JSON.parse(JSON.stringify(this.$route.query));
	  queryData.begin_time = this.staDate / 1000;
	  queryData.end_time = this.end/Date / 1000;
	  const type = typeof queryData.measure_id;
	  if (type == 'string'){
		queryData.measure_id = Number(queryData.measure_id);
	  }else{
		let arr = queryData.measure_id.map((item)=> Number(item));
		arr = JSON.stringify(arr);
		arr = arr.replace(/\[|]/g, '');
		queryData.measure_id = arr;
	  }
	  this.title = queryData.schoolName;
	  schoolStatistics(queryData).then((res) =>{
		if (res.code === 400200){
		  let { main, single }  = res.data;
		  this.scdx = main.scdx;
		  this.scxm = main.scxm;
		  this.nnjxbfb = main.nnjxbfb;
		  this.arrangementNation(main.mzfb);
		  this.arrangementAgeSexChart(main.nnjxbfb);
		  // 子表
		  this.single = single;
		  this.onClass();
		  this.onGrades();
		  this.arrangementAItemChart();
		  console.log(this.single);
		  this.single.forEach((item) => {
			item.zjfx = item.zjfx.replace(/\n/g, '<br>')
		  });
		  // this.arrangementAItemChart(single.bjqk);
		}
	  });
	},
	// 图表渲染
	chart(maxarr){
	  // 年龄性别
	  let chart1 = this.$echarts.init(
	    document.getElementById("chart-report-1")
	  );
	  chart1.setOption({
	    backgroundColor: "#FFFFFF",
	    title: {
	      x: "center",
	      padding: [12, 0, 0, 0],
	      textStyle: {
	        fontSize: 20,
	        fontWeight: "bolder",
	        color: "#333",
	      },
	    },
	    tooltip: {
	      trigger: "axis",
	      axisPointer: {
	        type: "shadow",
	      },
	    },
	    grid: {
	      left: "3%",
	      right: "4%",
	      bottom: "10%",
	      top: "25%",
	      containLabel: true,
	    },
		legend: {
		    data: ["男性", "女性", "合计"],
			x:'right',
			icon: "circle",
			itemWidth: 20,
			itemHeight: 20,
		},
	    // color:['#107af4', '#fe632f', '#009a6e'],
	    xAxis: [
	      {
	        type: "category",
	        data: this.agesexfb.name,
	      },
	    ],
	    yAxis: [
	      {
	        min: 0,
	        max: Math.max(...maxarr),
	        axisLabel: {
	          color: "#999",
	          textStyle: {
	            fontSize: 18,
	          },
	        },
	        axisLine: {
	          lineStyle: {
	            color: "rgba(107,107,107,0.8)",
	          },
	        },
	        axisTick: {
	          show: false,
	        },
	        splitLine: {
	          lineStyle: {
	            color: "#87bcf9",
	            type: "solid",
	          },
	        },
	      },
	    ],
	    series: [
	      {
			name: '男性',
	        type: "bar",
	        barWidth: 15,
	        label: {
	          normal: {
	            show: true,
	            position: "top"
	          },
	        },
	        data: this.agesexfb.nan,
	      },
	      {
			name: '女性',
	        type: "bar",
	        barWidth: 15,
	        label: {
	          normal: {
	            show: true,
	            position: "top"
	          },
	        },
	        data: this.agesexfb.nv,
	      },
	      {
			name: '合计',
	        type: "bar",
	        barWidth: 15,
	        label: {
	          normal: {
	            show: true,
	            position: "top"
	          },
	        },
	        data: this.agesexfb.tol,
	      },
	    ],
	  })
	  //  图片转BA64
	  let imgURL1 = chart1.getDataURL({
	    pixelRatio: 1,
	  });
	  this.imgUrl.push(imgURL1);
	},
  },
};
</script>
<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  // .seal {
  //   page-break-inside: avoid;
  // }
  // .pageBreak{
  //   page-break-after: always; /*强制换页的关键*/
  // }
}
@import "./index.less";
</style>