<template>
	<div :id="idx" :style="{ width: '98%', height: '98%' }">
		
	</div>
</template>

<script>
    export default {
        name: 'ItemWorld',
        props: {
            msg: Object,
			idx: String,
        },
		data(){
			return{
				
			}
		},
		created() {
		  // console.log(this.$props.msg, "child");
		  const arr = [];
		  let data = this.fenge(this.$props.msg.cont.data, this.$props.msg.cont.name.length);
		  this.$props.msg.cont.name.forEach((item, index) => {
			let obj = {
				  name: item,
				  type: "bar",
				  barWidth: 15,
				  // label: {
				  //   normal: {
				  //     show: true,
				  //     position: "top"
				  //   },
				  // },
				  data: [],
				};
			data.forEach((itm) => {
			  itm.forEach((itm2, idx2) => {
				if (index === idx2){
					obj.data.push(itm2);
				}
			  })
			});
			arr.push(obj);
		  });
		  // console.log(arr, "dataarr");
		  this.$nextTick( () =>{
		    let chart1 = this.$echarts.init(
		    document.getElementById(this.$props.idx)
		    );
			chart1.setOption({
			  backgroundColor: "#FFFFFF",
			  title: {
			    x: "center",
			    padding: [12, 0, 0, 0],
			    textStyle: {
			      fontSize: 20,
			      fontWeight: "bolder",
			      color: "#333",
			    },
			  },
			  tooltip: {
			    trigger: "axis",
			    axisPointer: {
			      type: "shadow",
			    },
			  },
			  grid: {
			    left: "3%",
			    right: "4%",
			    bottom: "10%",
			    top: "25%",
			    containLabel: true,
			  },
			  legend: {
			      data: this.$props.msg.cont.name,
				  x:'right',
				  icon: "circle",
				  itemWidth: 20,
				  itemHeight: 20,
			  },
			  // color:['#107af4', '#fe632f', '#009a6e'],
			  xAxis: [
			    // {
			    //   type: "category",
			    //   data: this.$props.msg.cont.laber,
			    // },
				{
					type: "value",
					// data: this.$props.msg.cont.laber,
				}
			  ],
			  yAxis: [
				{
					type: "category",
					data: this.$props.msg.cont.laber,
					boundaryGap: true,
					 // min: 0,	
					  // max: Math.max(...this.$props.msg.cont.data),
					axisLabel: {
					  color: "#999",
					    textStyle: {
					    fontSize: 18,
					  },
					},
					axisTick: {
					  show: false,
					},
					splitLine:{
					  lineStyle:{
					    color: "#87bcf9",
						type: "solid",
					  }
					}
				}
			   //  {
				  // type: "category",
			   //    min: 0,
			   //    max: Math.max(...this.$props.msg.cont.data),
			   //    axisLabel: {
			   //      color: "#999",
			   //      textStyle: {
			   //        fontSize: 18,
			   //      },
			   //    },
			   //    axisLine: {
			   //      lineStyle: {
			   //        color: "rgba(107,107,107,0.8)",
			   //      },
			   //    },
			   //    axisTick: {
			   //      show: false,
			   //    },
			   //    splitLine: {
			   //      lineStyle: {
			   //        color: "#87bcf9",
			   //        type: "solid",
			   //      },
			   //    },
			   //  },
			  ],
			  series: arr
			})
		  })
		},
		methods: {
			// 分割data数据
			fenge(arr, n){
			  const result = [];
			  for(let i =0; i < arr.length;i+=n){
			    result.push(arr.slice(i, i+n))
			  }
			  return result;
			},
		}
  }
</script>
